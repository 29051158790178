const hexChars = "0123456789abcdef";

const generateUUID = (): string => {
	const sections = [8, 4, 4, 4, 12];
	const uuidParts = sections.map((section) => {
		let part = "";
		for (let i = 0; i < section; i++) {
			part += hexChars.charAt(Math.floor(Math.random() * 16));
		}
		return part;
	});

	return uuidParts.join("-");
};

export default generateUUID;
