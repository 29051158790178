import { apiClient } from "@netgame/openapi";
import generateUUID from "@netgame/utils/src/uuid";
import dayjs from "dayjs";

import useAppInitData from "./useAppInitData";
import useIsGuest from "./useIsGuest";

export type PopUpAdData = {
	popup_id: number;
	popup_name: string;
	popup_delay_time: number;
	popup_company_priority: number;
	popup_priority: number;
	popup_data: string;
	log_id: number;
};

export type PopupAdTriggerType = "userRegistration" | "closeGame" | "playerLogin" | "closeCashbox";

export type AdAction = {
	logId: number;
	actionId: string;
};

type CompanyInfo = {
	company_id: number;
	company_name: string;
	company_priority: number;
	company_popup_id: number;
};

type ContentInfo = {
	popup_id: number;
	popup_name: string;
	popup_delay_time: number;
	popup_priority: number;
	popup_data: string;
};

type LogInfo = {
	log_id: number;
	popup_id: number;
	user_id: string;
};

type GuestAdModalData = {
	companyInfo: CompanyInfo[];
	contentInfo: ContentInfo[];
	logInfo: LogInfo[];
};

type GuestSingleAdModalData = {
	companyInfo: CompanyInfo;
	contentInfo: ContentInfo;
	logInfo: LogInfo;
};

const usePopupAds = () => {
	const isGuest = useIsGuest();
	const popUpAds = useState("popUpAds");
	const { data: appInit } = useAppInitData();

	const trackingId = useCookie("tracking_id", {
		expires: dayjs().add(1, "year").toDate(),
		path: "/"
	});

	const checkDeviceId = () => {
		if (!appInit.value?.popUpAds?.isActive) {
			return;
		}

		if (trackingId.value) {
			return;
		}

		trackingId.value = generateUUID();
	};

	const transformPopUpAdData = (data: GuestAdModalData | GuestSingleAdModalData): PopUpAdData[] => {
		if (!data || !data?.companyInfo) {
			return [];
		}

		const result: PopUpAdData[] = [];

		if (typeof data.companyInfo === "object") {
			const guestSingleData = data as GuestSingleAdModalData;

			const popupData: PopUpAdData = {
				popup_id: guestSingleData?.contentInfo?.popup_id,
				popup_name: guestSingleData?.contentInfo?.popup_name,
				popup_delay_time: guestSingleData?.contentInfo?.popup_delay_time,
				popup_company_priority: guestSingleData?.companyInfo?.company_priority,
				popup_priority: guestSingleData?.contentInfo?.popup_priority,
				popup_data: guestSingleData?.contentInfo?.popup_data,
				log_id: guestSingleData?.logInfo?.log_id
			};

			result.push(popupData);
			return result;
		}

		const guestData = data as GuestAdModalData;

		for (let i = 0; i < guestData?.companyInfo?.length; i++) {
			const company = guestData?.companyInfo[i];
			const content = guestData?.contentInfo[i];
			const log = guestData?.logInfo[i];

			const popupData: PopUpAdData = {
				popup_id: content?.popup_id,
				popup_name: content?.popup_name,
				popup_delay_time: content?.popup_delay_time,
				popup_company_priority: company?.company_priority,
				popup_priority: content?.popup_priority,
				popup_data: content?.popup_data,
				log_id: log?.log_id
			};

			result.push(popupData);
		}
		if (result?.length > 1) {
			result?.sort((modalA, modalB) => modalA.popup_priority - modalB.popup_priority);
		}

		return result;
	};

	const sendAdTrigger = async (triggerType: PopupAdTriggerType) => {
		if (!appInit.value?.popUpAds?.isActive || !trackingId) {
			return;
		}

		await apiClient({
			path: `/rest/pop-up-ads/${isGuest.value ? "guest" : "player"}-popup-ads/`,
			method: "post",
			parameters: {
				body: {
					triggerType
				}
			},
			options: {
				onResponse: ({ response }) => {
					const data = response?._data?.[0];

					if (!data) {
						return;
					}

					const adModals = transformPopUpAdData(data);

					if (adModals?.length) {
						popUpAds.value = adModals;
					}
				}
			}
		});
	};

	const sendAdAction = async ({ logId, actionId }: AdAction) => {
		if (!appInit.value?.popUpAds?.isActive) {
			return;
		}

		await apiClient({
			path: "/rest/pop-up-ads/log-action/",
			method: "post",
			parameters: {
				body: {
					log_id: logId,
					action_id: actionId,
					is_player: !isGuest.value
				}
			}
		});
	};

	return { sendAdTrigger, sendAdAction, checkDeviceId };
};

export default usePopupAds;
